// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAps0AURYT7dw4d8v-OO1hEDmWKj-5Gby4",
  authDomain: "habit-score.firebaseapp.com",
  databaseURL: "https://habit-score-default-rtdb.firebaseio.com",
  projectId: "habit-score",
  storageBucket: "habit-score.appspot.com",
  messagingSenderId: "755511516911",
  appId: "1:755511516911:web:e01974afad8fdd4a56c51a"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
