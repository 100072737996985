import React from 'react';

// Theme Stuff
import HeadingSection from "../../components/HeadingSection/HeadingSection";
import CountersThree from "../../components/Counters/CountersThree";
import OurSkillsThree from "../../components/OurSkills/OurSkillsThree";
import CreateAccount from '../../helpers/CreateAccount';
import DisplayRings from '../../components/Display/DisplayRings';

const Teaser = ( { habitName, counterData, ringsData, barsData } ) => {

    return (
        <>
            <section>
                <div className="container">
                    <HeadingSection
                        title="Your Habit Score Results"
                        tagline={habitName}
                        >
                    </HeadingSection>

                    <DisplayRings data={ringsData} />

                </div>
            </section>

            <CreateAccount />

            <CountersThree data={counterData} />

            <section>
                <div className="container">
                    <HeadingSection
                        title="What The Numbers Mean"
                        tagline={habitName}
                        >
                        We are a fully in-house digital agency focusing on branding,
                        marketing, web design and development with clients ranging from
                        start-ups. We pride ourselves on partnering with clients in order to
                        give the most transparent and educational experience.
                    </HeadingSection>
                    <div className="row">
                        <div className="centerize-col col-md-6 mb-50">
                            <OurSkillsThree data={barsData} />
                        </div>
                    </div>
                </div> 
            </section>

            <CreateAccount />
        </>
    )
}

export default Teaser;