import React, {useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { get, getDatabase, ref } from 'firebase/database';
import { formatInTimeZone } from "date-fns-tz";

import Loader from "../../components/Loader/Loader";
import HeaderFive from "../../components/Header/HeaderFive";
import Icofont from "react-icofont";
import FooterSimple from "../../components/Footer/FooterSimple";
import UserContext from "../../components/Contexts/User";



const formatDate = ( epoch ) => {
  const date = new Date( epoch );
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // Aug 22, 2022 at HH:MM
  return formatInTimeZone( date, timezone, "MMM d, yyyy h:mmaaa");
}

function Dashboard() {
  const navigate = useNavigate();
  const [results,setResults] = useState( [] );
  
  const signedIn = useContext(UserContext);

  useEffect( () => {
    if( false === signedIn ) {
        navigate( "/login", { replace: true } );
    } else if( signedIn ) {
      const db = getDatabase();
      const query1 = ref(db, 'userResponses/' + signedIn.uid );
      get(query1).then( ( snapshot ) => {
        if (snapshot.exists()) {
          setResults( snapshot.val() );
        } else {
          console.log("No data available");
        }
      });
    }
  },[ signedIn ])



  return (
    <Loader>
      <HeaderFive />

      <section>
        <div className="container">
          <div className="row">
            <div className="centerize-col col-md-3">
            <Link
              to="/survey"
              className="btn btn-color btn-round btn-animate"
            >
              <span>
                Create New Survey
                <Icofont icon="simple-right" />
              </span>
            </Link>
            </div>   
            <div className="col-md-12 terms">
              <h2 className="font-700">Dashboard</h2>
              <ul className="careers-listing">
                {Object.entries(results).map(([id,score]) => (
                  <li key={id}>
                    <div className="row">
                      <div className="career-main col-md-9">
                        <h5 className="font-700">{score.habitName}</h5>
                        <span className="default-color career-type">
                          Habit Score: {score.totalScore}
                        </span>
                        <span className="career-location">
                          {formatDate(score.createdDate)}
                        </span>
                      </div>
                      <div className="career-details col-md-3">
                        <Link
                          to={`/results/${score.createdDate}`}
                          className="btn btn-color btn-round btn-animate"
                        >
                          <span>
                            View Results
                            <Icofont icon="simple-right" />
                          </span>
                        </Link>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <FooterSimple />
    </Loader>
  );
}
export default Dashboard;
