import React, {useEffect, useState, useContext } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { get, getDatabase, ref, orderByKey, limitToLast, query } from 'firebase/database';
import { keys } from "lodash";
import UserContext from "../../components/Contexts/User";

// Theme Stuff
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "../../components/Loader/Loader";
import HeadingSection from "../../components/HeadingSection/HeadingSection";
import HeaderThree from "../../components/Header/HeaderThree";
import HeaderFive from "../../components/Header/HeaderFive";
import FooterSimple from "../../components/Footer/FooterSimple";
import ScrollToTop from "../../helpers/ScrollToTop";
import Teaser from "./teaser";
import Summary from "./summary";
import Full from "./full";

function Results() {
    const navigate = useNavigate();
    const { surveyId } = useParams();
    const [loading,setLoading] = useState( true );

    const signedIn = useContext(UserContext);

    const [ surveyResults, setSurveyResults ] = useState( [] );
    const [ habitName, setHabitName ] = useState( "" );
    const [ counterData, setCounterData ] = useState( [] );
    const [ ringsData, setRingsData ] = useState( [] );
    const [ barsData, setBarsData ] = useState( [] );

    useEffect( () => {
      if( false === signedIn ) {
          navigate( "/login", { replace: true } );
      } else if( signedIn ) {
        const db = getDatabase();
        const query1 = ref(db, `userResponses/${signedIn.uid}/${surveyId}`)
        get(query1).then( ( snapshot ) => {
            const results = snapshot.val();
            setSurveyResults( results );
            setHabitName( results.habitName );
            setCounterData( [
                { 
                    id: 1,
                    value: results.totalScore,
                    title: "Habit Score",
                    icon: "gears"
                }
            ]);
            setRingsData( [
                {
                    id: 1,
                    title: "Total",
                    percentage: results.totalScore,
                    color: "#2d2d2d"
                },
                {
                    id: 2,
                    title: "Choice",
                    percentage: results.choiceScore,
                    color: "#d9534f"
                },
                {
                    id: 3,
                    title: "Design",
                    percentage: results.designScore,
                    color: "#f0ad4e"
                },
                {
                    id: 4,
                    title: "Install",
                    percentage: results.installScore,
                    color: "#5cb85c"
                }
            ]);
            setBarsData( [
                {
                    id: 1,
                    title: "Total",
                    percentage: results.totalScore,
                    color: "#2d2d2d"
                },
                {
                    id: 2,
                    title: "Choice",
                    percentage: results.choiceScore,
                    color: "#d9534f"
                },
                {
                    id: 3,
                    title: "Design",
                    percentage: results.designScore,
                    color: "#f0ad4e"
                },
                {
                    id: 4,
                    title: "Install",
                    percentage: results.installScore,
                    color: "#5cb85c"
                }
            ]);
            setLoading( false );
        } );
      }
    },[ signedIn ])
    useEffect(() => {
      AOS.init();
      AOS.refresh();
    }, []);

    let CallResult;
    if ( signedIn && signedIn.isAnonymous ) {
        CallResult = Teaser;
    } else if ( signedIn && signedIn.isSubscribed) {
        CallResult = Full;
    } else if ( signedIn && false === signedIn.isAnonymous ) {
        CallResult = Summary;
    }

    return (
        <Loader loading={loading}>
            <HeaderFive />

            { CallResult && <CallResult habitName={habitName} counterData={counterData} ringsData={ringsData} barsData={barsData} surveyResults={surveyResults} /> }

            <FooterSimple />
            <ScrollToTop />
        </Loader>
    );
}

export default Results;