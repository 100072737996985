import React, {useEffect} from "react";
import {
    CircularProgressbarWithChildren,
    buildStyles,
  } from "react-circular-progressbar";
import { easeQuadInOut } from "d3-ease";
import "react-circular-progressbar/dist/styles.css";
import AnimatedProgressProvider from "../../helpers/AnimatedProgressProvider";

const Ring = ( {skill} ) => ( 
    <div className="col-md-3 col-sm-6" >
        <div className="progress-ring">
            <AnimatedProgressProvider
            valueStart={0}
            valueEnd={skill.percentage}
            duration={0}
            easingFunction={easeQuadInOut}
            >
            {(value) => (
                <CircularProgressbarWithChildren
                value={value}
                strokeWidth={4}
                styles={buildStyles({
                    strokeLinecap: "butt",
                    pathTransitionDuration: "none",
                    pathColor: "#d42e22",
                })}
                >
                <span className="dark-color ring-bar-text">
                    {skill.title}
                </span>
                <div style={{ fontSize: 15, color: "#212121" }}>{value}%</div>
                </CircularProgressbarWithChildren>
            )}
            </AnimatedProgressProvider>
        </div>
    </div>
)

const DisplayRings = ({ data }) => (

    <div className="row">
    {data.map((skill) => <Ring skill={skill} key={skill.id}/>)}
    </div>

)

export default DisplayRings;