import React, { useState, useEffect } from "react";

const Loader = ({ children, loading=false }) => {

  return loading ? (
    <div id="loader-overlay">
      <div className="loader-wrapper">
        <div className="scoda-pulse"></div>
      </div>
    </div>
  ) : (
    <>{children}</>
  );
};

export default Loader;
