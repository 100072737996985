import { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as SurveyJs from "survey-react";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getDatabase, ref, set } from "firebase/database";
import UserContext from "../../components/Contexts/User";

// Theme Stuff
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "../../components/Loader/Loader";
import HeadingSection from "../../components/HeadingSection/HeadingSection";
import HeaderFive from "../../components/Header/HeaderFive";
import FooterSimple from "../../components/Footer/FooterSimple";

// Custom Suff
import dataSurvey from "../../data/habit-score-survey-data.json";
import { calcScore } from "../../helpers/surveyStart";

SurveyJs.StylesManager.applyTheme( "bootstrap" ); 

const answers = {
    "habit_name": "Test",
    "habit_category": "skill",
    "c1": 1,
    "c2": 3,
    "c3": 3,
    "c4": 5,
    "c5": 7,
    "c6": 5,
    "d1": 5,
    "d2": 5,
    "d3": 5,
    "d4": 5,
    "d5": 5,
    "d6": 5,
    "d7": 5,
    "d8": 5,
    "i1": 3,
    "i2": 5,
    "i3": 7,
    "i4": 3,
    "i5": 5,
    "i6": 7,
    "i7": 7
}

const answers2 = {
    "habit_name": "Test 2",
    "habit_category": "skill",
    "c1": 7,
    "c2": 7,
    "c3": 7,
    "c4": 7,
    "c5": 7,
    "c6": 7,
    "d1": 7,
    "d2": 7,
    "d3": 7,
    "d4": 7,
    "d5": 7,
    "d6": 7,
    "d7": 7,
    "d8": 7,
    "i1": 7,
    "i2": 7,
    "i3": 7,
    "i4": 7,
    "i5": 7,
    "i6": 7,
    "i7": 7
}

const answers3 = {
    "habit_name": "Test 2",
    "habit_category": "skill",
    "c1": 1,
    "c2": 1,
    "c3": 1,
    "c4": 1,
    "c5": 1,
    "c6": 1,
    "d1": 1,
    "d2": 1,
    "d3": 1,
    "d4": 1,
    "d5": 1,
    "d6": 1,
    "d7": 1,
    "d8": 1,
    "i1": 1,
    "i2": 1,
    "i3": 1,
    "i4": 1,
    "i5": 1,
    "i6": 1,
    "i7": 1
}

// What do save?
// habit_name
// habit_category
// survey_type (start or stop)
// survey_version number 1
// total_score
// choice_score (c1-5)
// design_score (d1-8)
// install_score (i1-6)
// all responses (c1-5,d1-8,i1-6)


export default function Survey() {
    const signedIn = useContext(UserContext);
    const navigate = useNavigate();
    const [ model ] = useState( new SurveyJs.Model(dataSurvey) );

    const handleComplete = useCallback( (answers) => {
        const response = calcScore(answers);
        const db = getDatabase();
        set(ref(db, 'userResponses/' + signedIn.uid + '/' + response.createdDate ), response)
            .then( () => navigate( `/results/${response.createdDate}` ) );
    })

    useEffect( () => {
        const auth = getAuth();
        if( false === signedIn ) {
            signInAnonymously(auth);
        }
    },[ signedIn ])

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

    return (
        <Loader>
            <HeaderFive />
            <section>
                <div className="container">
                    <HeadingSection
                        title="Habit Score Assessment"
                        tagline="Complete assessment to calc your habit score"
                        >
                    </HeadingSection>
                    <button onClick={() => handleComplete(answers)}>Test</button>
                    <button onClick={() => handleComplete(answers2)}>Test = All 7's</button>
                    <button onClick={() => handleComplete(answers3)}>Test = All 1's</button>
                    <SurveyJs.Survey model={model} onComplete={ (x) => handleComplete( x.data ) } />
                </div>
                </section>
                <section></section>
            <FooterSimple />
        </Loader>
    )
}
