import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, EmailAuthProvider, linkWithCredential } from 'firebase/auth';
import PasswordStrengthBar from 'react-password-strength-bar';
import { Alert } from 'react-bootstrap';

import HeadingSection from "../components/HeadingSection/HeadingSection";
import HeadingSix from "../components/HeadingSection/HeadingSix";

const CreateAccount = () => {
    const navigate = useNavigate();

    const [signupError, setSignupError ] = useState( null );
    const [password, setPassword ] = useState( "" );

    const handleLogin = useCallback( ( e ) => {
        setSignupError( null );
        const auth = getAuth();
        e.preventDefault();
        const credential = EmailAuthProvider.credential(e.target.elements.namedItem( "username" ).value, e.target.elements.namedItem( "password" ).value);
        linkWithCredential(auth.currentUser, credential)
            .then( () => navigate( 0 ))
            .catch( (e) => {
                if ( e.code === "auth/invalid-email" ) {
                    setSignupError( "ERROR: INVALID EMAIL" );
                } else if ( e.code === "auth/email-already-in-use" ) {
                    setSignupError( "ERROR: EMAIL ALREADY IN USE" );
                } else if ( e.code === "auth/weak-password" ) {
                    setSignupError( "ERROR: WEAK PASSWORD" );
                } else {
                    setSignupError( "ERROR: UNABLE TO CREATE ACCOUNT" );
                    console.log( e.code );
                }
            } )
      },[]);

    return ( 
        <section>
        <div className="container">
            <div className="row">
                <HeadingSix
                    title="Unlock Additional Insights"
                    tagline="Create your free account to unlock additional insights"
                    >
                </HeadingSix>
                <div className="row">
                    <div className="centerize-col col-md-4 mb-50">
                        <form method="post" className="form login_type text-center" onSubmit={ handleLogin }>
                            <div className="form-floating">
                            <input
                                type="text"
                                name="username"
                                className="form-control mb-20"
                                placeholder="Username"
                            />
                            <label htmlFor="username">Email</label>
                            </div>
                            <div className="form-floating">
                            <input
                                type="password"
                                name="password"
                                className="form-control mb-20"
                                placeholder="Password"
                                onChange={ (e) => setPassword(e.target.value) }
                            />
                            <label htmlFor="password">Password</label>
                            <PasswordStrengthBar password={password} minLength='6' />
                            </div>
                            {
                                signupError && <Alert className="border-radius-25" variant="warning">
                                    {signupError}
                                </Alert>
                            }
                            <button
                            type="submit"
                            name="login"
                            className="btn btn-color btn-square full-width"
                            >
                            CREATE ACCOUNT
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
};

export default CreateAccount;