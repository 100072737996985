import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "../../components/Loader/Loader";
import ScrollToTop from "../../helpers/ScrollToTop";

import dataHeroScore from "../../data/habit-score-data.json";

//import HeaderOne from "../../components/Header/HeaderOne";
import HeaderThree from "../../components/Header/HeaderThree";
import HeroSliderSeven from "../../components/Hero/HeroSliderSeven";
import WhoWeAreSix from "../../components/WhoWeAre/WhoWeAreSix";
import CounterTwo from "../../components/Counters/CounterTwo";
import FooterSimple from "../../components/Footer/FooterSimple";

import UserContext from "../../components/Contexts/User";

const HeroScore= () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const navigate = useNavigate();
  const signedIn = useContext(UserContext);

  useEffect( () => {
    if( signedIn && signedIn.isAnonymous === false) {
        navigate( "/dashboard", { replace: true } );
    }
  },[ signedIn ])

  return (
    <Loader>
      <HeaderThree />
      <HeroSliderSeven data={dataHeroScore} />
      <WhoWeAreSix
        title="The higher your habit score, the sticker the habit!"
        tagline="Increase the “stickiness” of your habits by ..."
      />
      <CounterTwo bg="dark-bg" />
      <HeroSliderSeven data={dataHeroScore} />
      <FooterSimple />
      <ScrollToTop />
    </Loader>
  );
};
  
export default HeroScore;