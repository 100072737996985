import React, { useEffect, useRef, useState, forwardRef, useMemo } from "react";
import { map, sortBy } from "lodash"; 
import dataPortfolio from "../../data/Portfolio/habit-score-data.json";
import PortfolioFilter from "../../elements/Portfolio/PortfolioFilter";
import PortfolioItem from "./PortfolioItem";
import Shuffle from "shufflejs";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";


const hold = {
  "id": "upgrade",
  "title": "Upgrade To Unlock",
  "category": "UPGRADE",
  "image": "habit-score/upgrade.jpeg",
  "groups": ["all", "choice", "design", "install"],
  "links": [
    {
      "id": 1,
      "icon": "search-1",
      "link": "!#"
    }
  ]
}

const surveyPriority = {
  "c1": 1,
  "c2": 9,
  "c3": 16,
  "c4": 17,
  "c5": 10,
  "c6": 5,
  "d1": 8,
  "d2": 14,
  "d3": 15,
  "d4": 13,
  "d5": 19,
  "d6": 21,
  "d7": 20,
  "d8": 18,
  "i1": 2,
  "i2": 3,
  "i3": 4,
  "i4": 6,
  "i5": 11,
  "i6": 12,
  "i7": 7
}

const Portfolio = forwardRef(
  ({ data, filter, layout, columns, space, items, classAppend, children, full=false }, ref) => {
    const categories = ["all", "choice", "design", "install"];
    const element = useRef();
    const [shuffle, setShuffle] = useState();

    const boxData = useMemo( () => {
      const x = map( dataPortfolio, ( item ) => ( { ...item, priority: surveyPriority[item.id], answer: data[item.id] } ) );
      const sorted = sortBy( x, ["answer", "priority"] );
      if (full) {
        return sorted;
      } else {
        return map( sorted, ( item, index ) => {  
          if( index < 3 ) { return item; }
          item.title =  "Upgrade To Unlock";
          item.image = "habit-score/upgrade.jpeg";
          return item;
        } );
      }
    }, [ data ]);

    useEffect( () => { 
      if (shuffle) { setTimeout( () => shuffle.sort(), 500 ) }
    }, [ shuffle ]); 

    useEffect(() => {
      if (element.current) {
        setShuffle(
          new Shuffle(element.current, {
            itemSelector: ".portfolio-item",
          })
        );
      }
      
    }, []);

    const filterElements = (evt) => {
      const btn = evt.currentTarget;
      evt.target.parentElement
        .querySelectorAll(".active")
        .forEach((e) => e.classList.remove("active"));
      evt.currentTarget.classList.add("active");
      const cat = btn.getAttribute("value");
      shuffle.filter((element) => {
        return element.getAttribute("data-groups").toLowerCase().includes(cat);
      });
    };

    const [isOpen, setIsOpen] = useState(false);
    const [photo, setPhoto] = useState(0);

    const closeLightbox = () => {
      setIsOpen(false);
    };
    const openLightbox = (e, photo) => {
      e.preventDefault();
      setPhoto(photo);
      setIsOpen(true);
    };

    return (
      <section
        className={"pb-0 " + (classAppend ? classAppend : "")}
        id="work"
        ref={ref}
      >
        {children ? (
          <div className="container">
            <div className="row">{children}</div>
          </div>
        ) : null}
        <div className={"container" + (layout === "wide" ? "-fluid" : "")}>
          <div className="row">
            <div className={"container" + (layout === "wide" ? "-fluid" : "") + " text-center"}>
              {filter === "true" ? (
                <PortfolioFilter
                  categories={categories}
                  handleClick={filterElements}
                />
              ) : null}

              <div
                id="portfolio-grid"
                ref={element}
                className="hover-two row"
              >
                { boxData.map((item, i) => (
                      <PortfolioItem
                        key={item.id}
                        title={item.title}
                        category={item.category}
                        image={item.image}
                        groups={item.groups}
                        space={space ? "true" : "false"}
                        columns={columns}
                      />
                    ))}
              </div>
              {isOpen && (
                <Lightbox
                  mainSrc={require("../../assets/images/" + photo)}
                  onCloseRequest={() => closeLightbox()}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default Portfolio;
