import React, { useState, useRef, useEffect } from "react";
import { onValue, getDatabase, ref } from 'firebase/database';
import Icofont from "react-icofont";
import {useCountUp} from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import counterData from "../../data/Counters/counter-data2.json";

const CounterTwo = ({ bg, type }) => {
  const [viewed, setViewed] = useState(false);
  const countUpRef = useRef(null)
  const {start, update} = useCountUp({ref:countUpRef, end:0, startOnMount:false});
  const viewChangeHandler = (isVisible) => {
    if (isVisible && false === viewed) {
      start();
      setViewed(true);
    } 
  };

  useEffect( () => {
    const db = getDatabase();
    const query1 = ref(db, 'siteStats/totalSurveys' );
    onValue(query1, (snapshot) => {
      const value = snapshot.val();
      update(value);
    })
  });

  return (
    <section className={"pt-80 pb-80 " + (bg ? bg : "dark-bg")}>
      <div className={"container" + (type === "wide" ? "-fluid" : "")}>
        <div className="row">
          {counterData.map((counter, i) => (
            <div
              key={counter.id}
              className="counter text-center wow fadeTop"
              data-wow-delay="0.1s"
              data-aos-delay={`${i}00`}
              data-aos={"fade-up"}
              data-aos-easing={"ease-in-sine"}
            >
              <Icofont icon={counter.icon} className="default-icon font-30px" />
              <h2
                className={
                  "count font-700 " + (bg === "white-bg" ? "" : "white-color")
                }
              >
                <VisibilitySensor onChange={viewChangeHandler} delayedCall>
                  <div ref={countUpRef}>0</div>
                </VisibilitySensor>
              </h2>
              <h3 className={bg === "white-bg" ? "dark-color" : ""}>
                {counter.title}
              </h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CounterTwo;
