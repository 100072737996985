import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import app from './firebase'; // this is required 
import { getFunctions, httpsCallable } from "firebase/functions";

import Home from './pages/Home/home';
import Survey from './pages/survey/survey';
import Results from './pages/results/results';
import Login from './pages/login/login';
import Dashboard from './pages/dashboard/dashboard';
import Privacy from './pages/others/privacy';
import Terms from './pages/others/terms';

import Page404 from "./pages/others/Page404";
import UserContext from './components/Contexts/User';

const functions = getFunctions(app);
const isSubscribed = httpsCallable(functions, 'isSubscribed');

export default function App() {

  const auth = getAuth();

  const [ signedIn, setSignedIn ] = useState( null );

  useEffect( () => {
      const watcher = ( user ) => {
        if ( user ) {
          isSubscribed()
            .then((result) => {
              user.isSubscribed = result.data.subscribed;
              setSignedIn( user );
            });
        } else {
          setSignedIn( false );
        }
      }
      return onAuthStateChanged( auth, watcher );
  }, [ onAuthStateChanged, signedIn ]);

  return (
    <>
      <UserContext.Provider value={signedIn}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/survey" element={<Survey />} />
            <Route path="/results/:surveyId" element={<Results />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />  
            <Route path="*" element={<Page404 />} />      
          </Routes>
        </Router>
      </UserContext.Provider>
    </>
  )
};
