import React, { useState } from "react";
import Icofont from "react-icofont";
import MainLogo from "../MainLogo";
import NavBar from "../NavBar/NavBar";

const HeaderFive = ({ data, scrollToSection }) => {
  const [collapse, setCollapse] = useState(false);

  const showMenu = () => {
    setCollapse(!collapse);
    const menu = document.getElementById("navbar-menu");
    collapse ? menu.classList.remove("in") : menu.classList.add("in");
  };

  return (
    <nav
      className="navbar-scrollspy navbar-five navbar navbar-expand-lg navbar-light navbar-fixed white bootsnav on no-full"
      data-minus-value-desktop="70"
      data-minus-value-mobile="55"
      data-speed="1000"
    >
      <div className="container">
        <button
          type="button"
          className={"navbar-toggler " + (collapse ? "collapsed" : "")}
          data-toggle="collapse"
          data-target="#navbar-menu"
          aria-expanded="false"
          onClick={() => showMenu()}
        >
          <Icofont icon="navigation-menu" />
        </button>
        <MainLogo showMenu={showMenu} />
        <div
          className="navbar-collapse collapse"
          id="navbar-menu"
          aria-expanded="false"
        >
        <NavBar />
        </div>
      </div>
    </nav>
  );
};

export default HeaderFive;
