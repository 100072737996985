import React from 'react';

// Theme Stuff
import HeadingSection from "../../components/HeadingSection/HeadingSection";
import CountersThree from "../../components/Counters/CountersThree";
import DisplayRings from '../../components/Display/DisplayRings';
import Portfolio from "../../components/Portfolio/Portfolio";
import PriceTablesTwo from "../../components/PriceTables/PriceTablesTwo";

function Summary( { habitName, counterData, ringsData, barsData, surveyResults } ) {
    return (
        <>
            <section>
                <div className="container">
                    <HeadingSection
                        title="Your Habit Score Results"
                        tagline={habitName}
                        >
                    </HeadingSection>
                    <DisplayRings data={ringsData} />
                </div>
            </section>

            <CountersThree data={counterData} />

            <section className="pt-100 pt-100">
                <HeadingSection
                        title="Top Recommendations"
                        tagline="Here's how to improve your habits score"
                        >
                </HeadingSection>
                <Portfolio data={surveyResults} filter="true" columns="3" layout="box" space="true" />
                <PriceTablesTwo
                    title="UPGRADE TO UNLOCK"
                    tagline="Choose your plan"
                    btnType="round"
                    classes="pb-0"
                />
            </section>

        </>
    )
}

export default Summary;