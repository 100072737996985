import React, { useCallback, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Alert } from 'react-bootstrap';

import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from "react-router-dom";

import Loader from "../../components/Loader/Loader";
import HeaderFive from "../../components/Header/HeaderFive";

function Login() {
    const navigate = useNavigate();

    const [loginError, setLoginError ] = useState( null );

    const handleLogin = useCallback( ( e ) => {
      setLoginError( null );
      const auth = getAuth();
      e.preventDefault();
      const userName = e.target.elements.namedItem( "username" ).value
      const password = e.target.elements.namedItem( "password" ).value
      signInWithEmailAndPassword( auth, userName, password  )
        .then( () => navigate( "/dashboard", { replace: true } ) )
        .catch( (e) => {
          if ( e.code === "auth/invalid-email") {
            setLoginError( "ERROR: INVALID EMAIL" );
          } else if ( e.code === "auth/user-not-found"  ) {
            setLoginError( "ERROR: EMAIL NOT FOUND" );
          } else if( e.code === "auth/wrong-password" ) {
            setLoginError( "ERROR: WRONG PASSWORD" );
          } else {
            setLoginError( "ERROR: UNABLE TO LOGIN" );
            console.log( e.code );
          }
        } );
    },[]);

    return (
      <Loader>
        <HeaderFive />
        <section
          className="title-hero-bg login-cover-bg"
          data-stellar-background-ratio="0.2"
        >
          <div className="table-display">
            <div className="login v-align text-center">
              <Tabs className="signup-box">
                <TabList id="signup-tabs" className="nav nav-tabs">
                  <Tab>
                    <a
                      data-toggle="tab"
                      href="!#login"
                      onClick={(e) => e.preventDefault()}
                    >
                      Login
                    </a>
                  </Tab>
                  <Tab>
                    <a
                      data-toggle="tab"
                      href="!#reset"
                      onClick={(e) => e.preventDefault()}
                    >
                      Reset Password
                    </a>
                  </Tab>
                </TabList>
                <div id="signup-content" className="tab-content">
                  <TabPanel id="login" className="tab-pane active in active">
                    <form method="post" className="form login_type text-center" onSubmit={ handleLogin }>
                      <div className="form-floating">
                        <input
                          type="text"
                          name="username"
                          id="username-input"
                          className="form-control mb-20"
                          placeholder="Username"
                        />
                        <label htmlFor="username">Email</label>
                      </div>
                      <div className="form-floating">
                        <input
                          type="password"
                          name="password"
                          className="form-control mb-20"
                          placeholder="Password"
                        />
                        <label htmlFor="password">Password</label>
                      </div>
                      {
                        loginError && <Alert className="border-radius-25" variant="warning">
                          {loginError}
                        </Alert>
                      }
                      <button
                        type="submit"
                        name="login"
                        className="btn btn-color btn-square full-width"
                      >
                        LOGIN
                      </button>
                    </form>
                  </TabPanel>
                  <TabPanel id="reset" className="tab-pane active in active">
                    <form method="post" className="form login_type text-center">
                      <div className="form-floating">
                        <input
                          type="text"
                          name="username"
                          className="form-control mb-20"
                          placeholder="Your Username"
                        />
                        <label htmlFor="username">Email</label>
                      </div>
                      <button
                        type="submit"
                        name="submit"
                        className="btn btn-color btn-square full-width"
                      >
                        RESET PASSWORD
                      </button>
                    </form>
                  </TabPanel>
                </div>
              </Tabs>
            </div>
          </div>
        </section>
      </Loader>
    );
}

export default Login;