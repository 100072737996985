import React from "react";

const PageTitleImage = ({ title, image }) => (
  <section
    className={"title-hero-bg widget-" + image + "-bg"}
    data-stellar-background-ratio="0.2"
  >
    <div className="container">
      <div className="page-title text-center">
        <h1>{title}</h1>
      </div>
    </div>
  </section>
);

export default PageTitleImage;