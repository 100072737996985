import { flow, toPairs, fromPairs, sum, values } from "lodash";
import { groupBy, mapValues } from "lodash/fp";

// What do save?
// habit_name
// habit_category
// survey_type (start or stop)
// survey_version number 1
// total_score
// choice_score (c1-5)
// design_score (d1-8)
// install_score (i1-6)
// all responses (c1-5,d1-8,i1-6)
// dateStamp

// This way I can adjust name/desc/url for each question after the user has completed the survey
// If I retire or add a new quesiton, don't reuse question names (c1-5,d1-8,i1-6)

// before survey is displayed to user, merge saved data with surveyMaster

const y = flow( [ 
    toPairs, groupBy( ( [ key ] ) => key.slice(0,1) ), mapValues( fromPairs )
] )

const categoryScore = flow( [
    values, sum
] )


//
// TODO
// - how to count entires in x.c or x.d or x.i ???
// - total score should all entries / totalcount*7
//
export const calcScore = ( answers ) => {
    const x = y( answers )
    const choiceScore = Math.round(categoryScore(x.c)/42*100);
    const designScore = Math.round(categoryScore(x.d)/56*100);
    const installScore = Math.round(categoryScore(x.i)/49*100);
    const totalScore = Math.round((choiceScore + designScore + installScore)/3);
    const responses = { choice: x.c, design: x.d, install: x.i };
    const habitName = answers.habit_name;
    const habitCategory = answers.habit_category;
    const surveyType = "start";
    const surveyVersion = 1;
    const createdDate = Date.now();

    return( { habitName, habitCategory, choiceScore, designScore, installScore, totalScore, responses, surveyType, surveyVersion, createdDate } );
}
