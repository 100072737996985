import React, { useContext } from "react";
import UserContext from "../Contexts/User";
import { getAuth, signOut } from "firebase/auth";

const LoggedOutNav = () => (
    <>
    <li>
    <a
        href="/login"
    >
        Login
    </a>
    </li>
    </>
)

const LoggedInNav = () => {

    const auth = getAuth();

    return (
        <>
        <li>
        <a
            href="/dashboard"
        >
            Dashboard
        </a>
        </li>
        <li>
        <a
            href="#" onClick={ () => signOut( auth ) }
        >
            Logout
        </a>
        </li>
        </>
    )
}


export default () => {

    const maybeUser = useContext(UserContext);
    const realUser = maybeUser && maybeUser.isAnonymous === false;

    return(

        <ul className="nav navbar-nav navbar-right nav-scrollspy-onepage">
            {realUser ? ( <LoggedInNav /> ) : (  <LoggedOutNav /> )}
        </ul>

    );
}