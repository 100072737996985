import React from "react";

const FooterSimple = () => (
  <>
    <footer className="footer" id="footer-fixed">
        <div className="footer-copyright">
            <div className="container">
                <div className="row">
                    <div className="footer-copyright">
                        © 2022 Rapid Success Coaching Inc. All rights reserved
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <div className="footer-height" style={{ height: "140px" }}></div>
  </>
);

export default FooterSimple;
